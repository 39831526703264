var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-container",
            { staticStyle: { "max-width": "1600px" }, attrs: { fluid: "" } },
            [
              _c(
                "v-expansion-panels",
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v(" Search "),
                        _c("span", { staticStyle: { "padding-left": "2px" } }, [
                          _vm._v("(" + _vm._s(_vm.totalRecord) + ")"),
                        ]),
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Load Number",
                                      type: "text",
                                    },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.searchAllReceivableLoads()
                                      },
                                    },
                                    model: {
                                      value: _vm.searchParam.loadNumber,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchParam,
                                          "loadNumber",
                                          $$v
                                        )
                                      },
                                      expression: "searchParam.loadNumber",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Customer Ref. Id",
                                      type: "text",
                                    },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.searchAllReceivableLoads()
                                      },
                                    },
                                    model: {
                                      value: _vm.searchParam.customerRefId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchParam,
                                          "customerRefId",
                                          $$v
                                        )
                                      },
                                      expression: "searchParam.customerRefId",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c("customer-auto-complete", {
                                    staticClass: "pt-3",
                                    on: { event: _vm.selectedCustomer },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "blue white--text dark-3",
                                      attrs: { small: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.searchAllReceivableLoads()
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v(" mdi-search-web "),
                                      ]),
                                      _vm._v(" Filter "),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ml-1",
                                      attrs: { small: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.resetSearch()
                                        },
                                      },
                                    },
                                    [_vm._v(" Reset ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-container",
            { staticClass: "pt-0 data-container", attrs: { fluid: "" } },
            [
              _c("v-data-table", {
                staticClass: "elevation-2 data-table",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.receivableLoads,
                  "items-per-page": _vm.pageSize,
                  loading: _vm.loadingIcon,
                  "calculate-widths": "",
                  "fixed-header": "",
                  "hide-default-footer": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.loadNumber",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              to: {
                                name: "Load Detail",
                                params: { id: item.loadNumber },
                              },
                              small: "",
                              target: "_blank",
                              text: "",
                            },
                          },
                          [_vm._v(" " + _vm._s(item.loadNumber) + " ")]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.loadStatus",
                    fn: function ({ item }) {
                      return [
                        _c("load-status-button", {
                          attrs: {
                            "agent-detail": _vm.agentDetail,
                            "disable-click": true,
                            "load-status-items": _vm.loadStatus,
                            "shipment-detail": item,
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "item.carrier",
                    fn: function ({ item }) {
                      return [
                        item.carrier
                          ? _c(
                              "p",
                              { staticClass: "mb-0" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "name-link",
                                    attrs: {
                                      to: {
                                        name: "Carrier Detail",
                                        params: {
                                          id: item.carrier.id,
                                          title:
                                            item.carrier.name +
                                            " - " +
                                            item.carrier.mc_number,
                                        },
                                      },
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(item.carrier.name)),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        item.carrier.ar_name
                          ? _c("span", [
                              _c("b", [_vm._v("AR:")]),
                              _vm._v(" " + _vm._s(item.carrier.ar_name) + " "),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "item.customer",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "router-link",
                          {
                            staticClass: "name-link",
                            attrs: {
                              to: {
                                name: "Customer Detail",
                                params: { id: item.customer.id },
                              },
                              target: "_blank",
                            },
                          },
                          [_vm._v(" " + _vm._s(item.customer.name) + " ")]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.shipper",
                    fn: function ({ item }) {
                      return [
                        item.shipper !== null
                          ? _c(
                              "p",
                              { staticClass: "mb-0" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatLocation(
                                        item.shipper.city,
                                        item.shipper.state_province_region
                                      )
                                    ) +
                                    " "
                                ),
                                _c("br"),
                                item.pickupDate !== null
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatDate(item.pickupDate)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  : _vm._e(),
                                item.pickupEarlyTime !== null
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.parseTime(item.pickupEarlyTime)
                                          ) +
                                          " - " +
                                          _vm._s(
                                            _vm.parseTime(item.pickupLateTime)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "item.consignee",
                    fn: function ({ item }) {
                      return [
                        item.consignee !== null
                          ? _c(
                              "p",
                              { staticClass: "mb-0" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatLocation(
                                        item.consignee.city,
                                        item.consignee.state_province_region
                                      )
                                    ) +
                                    " "
                                ),
                                _c("br"),
                                item.deliveryDate !== null
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatDate(item.deliveryDate)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  : _vm._e(),
                                item.deliveryEarlyTime !== null
                                  ? [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.parseTime(
                                              item.deliveryEarlyTime
                                            )
                                          ) +
                                          " - " +
                                          _vm._s(
                                            _vm.parseTime(item.deliveryLateTime)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "item.customerPrice",
                    fn: function ({ item }) {
                      return [
                        item.customerPrice
                          ? _c(
                              "span",
                              { staticClass: "blue--text text--darken" },
                              [_vm._v("$" + _vm._s(item.customerPrice))]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "item.fuelSurcharge",
                    fn: function ({ item }) {
                      return [
                        item.fuelSurcharge
                          ? _c(
                              "span",
                              { staticClass: "blue--text text--darken" },
                              [_vm._v("$" + _vm._s(item.fuelSurcharge))]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "item.customerTotalPrice",
                    fn: function ({ item }) {
                      return [
                        item.customerPrice
                          ? _c(
                              "span",
                              { staticClass: "blue--text text--darken" },
                              [_vm._v("$" + _vm._s(item.customerTotalPrice))]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "item.edit",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-icon",
                          {
                            staticClass: "px-0",
                            attrs: { small: "", title: "Edit Carrier AR Info" },
                            on: {
                              click: function ($event) {
                                return _vm.editCarrierARInfo(item.carrier)
                              },
                            },
                          },
                          [_vm._v(" mdi-pencil ")]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.receivePayment",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-btn",
                          {
                            staticClass: "font-weight-bold green lighten-1",
                            attrs: { small: "" },
                            on: {
                              click: function ($event) {
                                return _vm.confirmChangeLoadStatus(
                                  item.loadNumber,
                                  item.customerTotalPrice
                                )
                              },
                            },
                          },
                          [_vm._v(" Receive Payment ")]
                        ),
                      ]
                    },
                  },
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c("v-page", {
                          staticClass: "pt-3 pr-3 pb-3",
                          attrs: {
                            "page-size-menu": [10, 20, 50, 100],
                            "total-row": _vm.totalRecord,
                          },
                          on: { "page-change": _vm.readDataFromAPI },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title"),
              _c("v-card-text", [
                _c("p", [_vm._v("Please confirm load amount received:")]),
                _c("p", { staticClass: "text-center mb-0" }, [
                  _c("b", [_vm._v("$" + _vm._s(_vm.customerTotalPrice))]),
                ]),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "grey darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "orange darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.loadAmountReceived()
                        },
                      },
                    },
                    [_vm._v(" Receive ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("carrier-a-r-edit", { on: { event: _vm.oprationEvent } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }